import { useState, useContext } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import InitiatePayment from "../components/payment";
import { UpdateSelectedComp } from '../components/updateSelectedComp';
// import { Onboarding } from "./onboarding";

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export function Competitions() {

    const { authenticatedUser, selectedCompetition, toggleCompetitionChanged } = useContext(AppContext); // Use context for login


    // User.competitions data
    const userCompetitions = (authenticatedUser.competitions);
    const noneJoined = userCompetitions && userCompetitions.length < 1;
    const selectedCompetitionUserInfo = userCompetitions && userCompetitions.find(obj => obj.selected === true)
    const numberOfAccounts = selectedCompetitionUserInfo && selectedCompetitionUserInfo.numberOfAccounts;
    const accountsNamed = selectedCompetitionUserInfo && selectedCompetitionUserInfo.accountsNamed;
    const email = authenticatedUser && authenticatedUser.email;

    // Screen view toggles
    const [showChooseCompetition, setShowChooseCompetition] = useState(true);
    const [showPurchaseAccounts, setShowPurchaseAccounts] = useState(false);
    const [showRenameAccounts, setShowRenameAccounts] = useState(false);
    const [noCompFound, setNoCompFound] = useState(false);

    // Input state
    const [quantity, setQuantity] = useState(1);
    const [joinCompetitionObj, setJoinCompetitionObj] = useState({});

    const navigate = useNavigate();

    // Add a loading state to check if data is available
    if (!selectedCompetition || !authenticatedUser || !toggleCompetitionChanged || Object.keys(selectedCompetition).length === 0) {
        return <div>Loading...</div>;  // Or a spinner component
    };

    function handlePinError() {
        setNoCompFound(true);
    };

    function handleShowChooseCompetition() {
        setShowPurchaseAccounts(false);
        setShowRenameAccounts(false);
        setShowChooseCompetition(true);
        window.location.reload();
    };

    function handlePurchaseAccounts() {
        setShowPurchaseAccounts(true);
        setShowRenameAccounts(false);
        setShowChooseCompetition(false);
    };

    function handleShowRenameAccounts() {
        setShowPurchaseAccounts(false);
        setShowRenameAccounts(true);
        setShowChooseCompetition(false)
    }


    function onStandings() {
        navigate('/standings');
    };

    function onSelections() {
        accountsNamed ? navigate('/selection') : alert('Please name your account(s) first!');
    };

    const onSubmit = async (event) => {
        event.preventDefault();
    };

    // Update selected competition
    const handleCompetitionSelection = async (compName) => {
        try {
            const result = await UpdateSelectedComp(compName, authenticatedUser.token);
            if (result.status === 200) {
                toggleCompetitionChanged(true);
                console.log(result.data.message);  // Access message from result.data
            } else if (result.status === 403) {
                console.log('Token invalid or expired');
            }
        } catch (err) {
            console.error('Error fetching user data:', err);
        }
    };


    function ChooseCompetition() {

        const [competitionPin, setCompetitionPin] = useState("");

        function handlePinInput(event) {
            // event.preventDefault();
            setCompetitionPin(event.target.value);
            // setNoCompFound(false);
        };

        async function handlePinSubmit() {
            if (!competitionPin) {
                return alert("Please enter Competition Pin");
            }

            if (competitionPin && authenticatedUser) {
                try {
                    const result = await axios.get(`${backend}/fetch/competition`, {
                        headers: {
                            authorization: `Bearer ${authenticatedUser.token}`,
                            request: competitionPin,
                        },
                    });

                    if (result.status === 204) {
                        setCompetitionPin(""); // Clear the input field
                        handlePinError();
                        return;
                    } else if (result.status === 200) {
                        console.log(`200 - ${result.data.message}`);
                        const { compParams, gameParams, fixtures } = result.data.payload;

                        // Extract round and startRound
                        const round = parseInt(gameParams.round.split('-')[1].trim(), 10);
                        const startRound = parseInt(compParams.startRound.split('-')[1].trim(), 10);

                        //Check if any fixture has a status.short not equal to "NS"
                        const commenced = Object.values(fixtures).some(item => item.fixture.status.short !== "NS");

                        if (round > startRound || commenced) {
                            // If the round is greater than the startRound, handle this case
                            alert("You cannot create further accounts as this competition has already started.");
                            return;
                        };

                        // Proceed if the round is valid
                        setJoinCompetitionObj(result.data.payload);
                        handlePurchaseAccounts();
                    }
                } catch (error) {
                    if (error.response) {
                        console.error('Server responded with an error:', error.response.data);
                        console.error('Status code:', error.response.status);
                        swal(error.response.data.message || "An error occurred while processing your request.");
                    } else if (error.request) {
                        console.error('No response received:', error.request);
                        swal("No response from the server. Please check your internet connection and try again.");
                    } else {
                        console.error('Error setting up request:', error.message);
                        swal("An unexpected error occurred. Please try again later.");
                    }
                }
            }
        }

        return (
            <>

                <div className="competition-container">

                    <h1>Your competitions</h1>
                    <h2>Select to change the active competition:</h2>
                    {userCompetitions && <select
                        style={{ textAlign: 'center' }}
                        value={selectedCompetition.name}
                        onChange={(event) => { handleCompetitionSelection(event.target.value) }}>
                        {userCompetitions.length > 0 ? userCompetitions.map((obj, index) => (
                            <option key={`${obj.name}-${index}`} value={obj.name}>
                                {obj.name}
                            </option>)) : null}
                    </select>}
                    {noneJoined && <><p></p><h3>{`(You haven't joined any competitions yet!)`}</h3></>
                    }
                    <p></p>
                    {!accountsNamed && !noneJoined && <button type="button" onClick={handleShowRenameAccounts}>Name accounts
                    </button>}
                    <div className="leagues-home-nav-buttons">
                        <button type="button" onClick={onSelections}>Selections</button>
                        <p></p>
                        <button type="button" onClick={onStandings}>Standings</button>
                    </div>
                </div>

                <div className="competition-container">
                    <h1>Join a competition</h1>
                    <h2>Enter the 'joining pin' given to you by your competition admin:</h2>
                    <input
                        type="text"
                        value={competitionPin}
                        onChange={handlePinInput}
                    />
                    <p></p>
                    {noCompFound && competitionPin.length < 1 && <><h2>{`Competition Pin not found! Please double-check the Pin provided by your Competition Admin and try again.`}</h2><p></p></>}
                    <button
                        type="button"
                        onClick={handlePinSubmit}>
                        Join competition
                    </button>
                    <p></p>
                </div>

                {/*<div className="competition-container">
                    <h1>Create a competition</h1>
                    <h3>This feature is coming soon! 💻</h3>
                    {/* <Onboarding />
                </div>*/}
            </>);
    };

    function PurchaseAccounts() {

        const compObj = joinCompetitionObj && joinCompetitionObj;

        const compParams = compObj && compObj.compParams;
        const name = compObj && compObj.name;
        const id = compObj && compObj.id;

        // Data for checkout
        const unitPrice = compParams.unitPrice;
        const totalPrice = unitPrice && (unitPrice * quantity);
        const totalPricePounds = totalPrice && (totalPrice / 100);

        const lineItems = [{
            quantity,
            currency: 'GBP',
            unit_amount: unitPrice,
            competitionName: name,
            competition_id: id,
            description: `${quantity} entries for ${name}'s LMS prize competition.`,
            images: []
        }];

        // Ensure that if the round is invalid, no further actions are taken
        if (!unitPrice) {
            return (
                <div className="competition-checkout-container">
                    <h1>Cannot Proceed</h1>
                    <p>You cannot purchase accounts as the current round has surpassed the start round.</p>
                    <button type="button" onClick={handleShowChooseCompetition}>Go back</button>
                </div>
            );
        }

        return (
            <>
                <div className="competition-checkout-container">
                    <form onSubmit={onSubmit} >
                        <h1>{name}</h1>
                        <h2>Please select the desired number of game entries.</h2>
                        <p></p>
                        <h2> 1 x competition entry = £{(unitPrice / 100).toFixed(2)} </h2>
                        <select
                            value={quantity}
                            onChange={(event) => { setQuantity(event.target.value) }}>
                            <option key="quantity 1" value="1">1</option>
                            <option key="quantity 2" value="2">2</option>
                            <option key="quantity 3" value="3">3</option>
                            <option key="quantity 4" value="4">4</option>
                            <option key="quantity 5" value="5">5</option>
                            <option key="quantity 6" value="6">6</option>
                            <option key="quantity 7" value="7">7</option>
                            <option key="quantity 8" value="8">8</option>
                            <option key="quantity 9" value="9">9</option>
                            <option key="quantity 10" value="10">10</option>
                        </select>
                        <p></p>
                        <h3>Total to pay: £{totalPricePounds.toFixed(2)}</h3>
                        <p></p>
                        <InitiatePayment lineItems={lineItems} authenticatedUser={authenticatedUser} />
                    </form>
                    <p></p>
                    <button type="button" onClick={handleShowChooseCompetition}>Go back</button>
                </div>
            </>
        )
    }

    function RenameAccounts() {
        const [accounts, setAccounts] = useState(Array(numberOfAccounts).fill(''));
        const [error, setError] = useState('');

        const onSubmit = async (event) => {
            event.preventDefault();

            if (accounts.includes('')) {
                return alert("Please enter an account name for each of your purchased accounts");
            }

            try {
                const result = await axios.post(`${backend}/authenticate/registeraccounts`, {
                    accounts,
                    email,
                    selectedCompetition,
                });

                if (result.data.status === "SUCCESS") {
                    alert("Account names submitted. You can now select between your accounts on the 'Selections' page. Good luck and happy gaming!");
                    toggleCompetitionChanged(true);
                    setError(''); // Clear error if submission is successful
                    handleShowChooseCompetition();
                } else {
                    setError(result.data.message);
                }
            } catch (err) {
                console.error(err);
                setError("An error occurred while submitting account names.");
            }
        };

        const handleAccountInputChange = (event, index) => {
            const newAccounts = [...accounts];
            newAccounts[index] = event.target.value;
            setAccounts(newAccounts);
        };

        return (
            <div className="competition-container">
                <h1>Register Accounts</h1>
                <div className="form">
                    <p></p>
                    <h2>Here you can enter a name for each of your accounts purchased for this league.</h2>
                    <p></p>
                    <h2>For users with multiple entries to the league, the account names will help you identify each different entry.</h2>
                    <p></p>
                    <h2>Account names will appear in the competition standings table which is visible to all players - therefore please keep all account names clean!</h2>

                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            {Array.from({ length: numberOfAccounts }, (_, index) => (
                                <div key={index}>
                                    <h3>Rename account {index + 1}:</h3>
                                    <input
                                        id={`account${index + 1}`}
                                        type="text"
                                        placeholder={`e.g. "${authenticatedUser.username} ${index + 1}"`}
                                        value={accounts[index]}
                                        onChange={(event) => handleAccountInputChange(event, index)}
                                        required
                                    />
                                </div>
                            ))}
                        </div>
                        <button type="submit">Register</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
                <p></p>
                <button type="button" onClick={handleShowChooseCompetition}>Return</button>
            </div>
        );
    }

    const pageColour = selectedCompetition ? selectedCompetition.compParams?.home_background : '#FFFFFF';

    const homeText = selectedCompetition && selectedCompetition.compParams?.home_text;

    return (
        <main style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            height: '100%',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            backgroundColor: `#${pageColour}`,
        }}>
            <div style={{
                display: 'flex',
                paddingTop: '3%',
                paddingBottom: '3%',
                margin: '0',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: `#${pageColour}`,
                width: '100 %'
            }}>
                {noneJoined ? <img src={"/logo.png"} alt="Logo" className={"banner-img-LMS"} /> : selectedCompetition.name === 'TestComp' ? <img src="/dummies.jpg" alt="dummies" className="banner-img" /> : <img src="/pirates_banner.jpg" alt="Logo" className="banner-img" />}
            </div>

            {!noneJoined &&
                <div className='leagues-home-container'>

                    <h1>{`Welcome to ${selectedCompetition?.name}'s Last Man Standing competition`}!</h1>
                    <h2>{`${homeText}`}</h2>
                    <br></br>
                </div>
            }
            <>
                {showChooseCompetition ? <ChooseCompetition /> : showPurchaseAccounts ?
                    <PurchaseAccounts
                        joinCompetitionObj={joinCompetitionObj}
                    /> :
                    showRenameAccounts ? <RenameAccounts /> :
                        <div></div>}
            </>
        </ main >
    )
}
