// Packages & routers
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Pages
import { Auth } from "./pages/auth";
import { CreateCompetition } from './pages/createCompetition';
import { Home } from "./pages/home";
import { Competitions } from "./pages/competitions";
import { Onboarding } from "./pages/onboarding";
import { Selection } from "./pages/selection";
import { Standings } from "./pages/standings";
import { Testing } from "./pages/testing";

// Components
import { Navbar } from './components/navbar';
import { AppProvider, useAppContext } from './AppProvider';  // Import context

// ProtectedRoute component
function ProtectedRoute({ element: Component }) {
  const { authenticatedUser } = useAppContext();

  return authenticatedUser ? <Component /> : <Auth />;
}

export default function App() {
  return (
    <div className="App">
      <AppProvider>
        <Router>
          <Navbar />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/onboarding" element={<ProtectedRoute element={Onboarding} />} />
            <Route path="/create-competition" element={<ProtectedRoute element={CreateCompetition} />} />
            <Route path="/competitions" element={<ProtectedRoute element={Competitions} />} />
            <Route path="/selection" element={<ProtectedRoute element={Selection} />} />
            <Route path="/standings" element={<ProtectedRoute element={Standings} />} />
            <Route path="/testing" element={<ProtectedRoute element={Testing} />} />
          </Routes>
        </Router>
      </AppProvider>
    </div>
  );
}
