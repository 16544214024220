import { useContext } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context

export function TableNew({ round, view }) {

    const { selectedCompetition, selectedAccount, selectionsAgg } = useContext(AppContext); // Use context for selectedAccount and competition

    const anyFixtureCommenced = selectedCompetition?.fixtures?.some(obj => obj.fixture.status.elapsed);

    const currentRound = (view === 'individual' || (view !== 'individual' && anyFixtureCommenced))
        ? Number(round)
        : Number(round) - 1;

    const gameweekStartRaw = selectedCompetition && (selectedCompetition.compParams?.startRound);

    const gameweekStart = parseInt(gameweekStartRaw.split(" - ")[1]);

    const numRounds = currentRound - (gameweekStart - 1);

    const columnData = Array.from({ length: numRounds }, (_, index) => String(gameweekStart + index));

    // Use the passed selectionsAgg
    const filteredSelections = view === 'individual' && selectedAccount
        ? selectionsAgg.filter(selection => selection.account_name === selectedAccount.name)
        : selectionsAgg;

    const accountResults = filteredSelections.reduce((acc, selection) => {
        const accountName = selection.account_name;
        const existingGroup = acc.find(group => group[0].account_name === accountName);

        if (existingGroup) {
            existingGroup.push(selection);
        } else {
            acc.push([selection]);
        }

        return acc;
    }, []);

    function setCellProps(cellObj) {
        if (!cellObj || cellObj.team_id === 0) {
            return ['standings-cell-missed', <img key="missed" className="standings-img" src="/homer.png" alt="non-selection" />];
        }

        const { result, team_logo } = cellObj;

        return result === 'lost'
            ? ['standings-cell-lost', <img key="lost" className="standings-img" src={team_logo || '/placeholder.png'} alt="lost" />]
            : result === 'won'
                ? ['standings-cell-won', <img key="won" className="standings-img" src={team_logo || '/placeholder.png'} alt="won" />]
                : result === 'pending'
                    ? ['standings-cell-pending', <img key="pending" className="standings-img" src={team_logo || '/placeholder.png'} alt="pending" />]
                    : null;
    }

    return (
        <table className="standings-component">
            <thead>
                <tr>
                    <th className="standings-header"></th>
                    {columnData.map(item => (
                        <th key={item} className="standings-header-rounds">{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {accountResults.map(account => (
                    <tr key={account[0].account_name}>
                        <td className="standings-firstcol">{account[0].account_name}</td>
                        {columnData.map((i) => {
                            const resultObj = account.find(selection => selection.round === `Regular Season - ${i}`);
                            const [className, jsxElement] = setCellProps(resultObj);

                            return (
                                <td key={i} className={className}>{jsxElement}</td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
