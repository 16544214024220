import { useState, useEffect, useContext } from "react";
import { AppContext } from '../AppProvider'; // Import AppProvider context
import { UpdateSelectedAccount } from './updateSelectedAccount';

export function AccountContainer() {
    const { authenticatedUser, selectedAccount, toggleAccountChanged, losingTeams } = useContext(AppContext); // Use context for login

    const [selectedAccountName, setSelectedAccountName] = useState('');
    const livesRemaining = losingTeams.length === 1 ? `2 lives remaining! ⚽⚽` : losingTeams.length === 2 ? `1 life remaining! 💀⚽` : `game over! 💀💀`;

    useEffect(() => {
        if (selectedAccount) {
            setSelectedAccountName(selectedAccount.name)
        }
    }, [selectedAccount]);

    // Update selected competition
    const handleAccountSelection = async (accountName) => {
        try {
            const result = await UpdateSelectedAccount(accountName, authenticatedUser.token);
            if (result.status === 200) {
                toggleAccountChanged(true);
                console.log(result.data.message);  // Access message from result.data
            } else if (result.status === 403) {
                console.log('Token invalid or expired');
            }
        } catch (err) {
            console.error('Error fetching user data:', err);
        }
    };

    function handleChange(e) {
        const accountName = e.target.value;
        handleAccountSelection(accountName);
    };

    if (selectedAccount && authenticatedUser.accounts) {
        return (
            <div className="account-container">
                <div className="account_select">
                    <h2>👋 {authenticatedUser.username}</h2>
                    <label className="account_select_label">Selected LMS account:
                        <br></br>
                        <select value={selectedAccountName} onChange={(e) => handleChange(e)
                        } className="account_dropdown">{authenticatedUser.accounts.map((item) => (<option key={item.name} value={item.name}>{item.name}</option>
                        ))}
                        </select>
                    </label>
                </div>
                <p>{livesRemaining}</p>
            </div>
        )
    }
};