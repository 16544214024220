import { useContext } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context
import { TableNew } from '../components/tableNew';
import { useNavigate } from "react-router-dom";

export function Standings() {

    const { selectedCompetition, toggleSelectionsAggReset, setTableOperation } = useContext(AppContext); // Use context for login

    const navigate = useNavigate(); // Move this outside the condition

    // Add a loading state to check if data is available
    if (!selectedCompetition || Object.keys(selectedCompetition).length === 0 || !toggleSelectionsAggReset || !setTableOperation) {
        return <div>Loading...</div>;  // Or a spinner component
    };

    const roundFull = selectedCompetition && selectedCompetition.gameParams?.round;
    const round = roundFull && roundFull.split("- ")[1];
    const view = "all";

    function onCompetitions() {
        navigate('/competitions');
    };

    function handleClickShowAll() {
        toggleSelectionsAggReset(true);
    };

    function handleClickHideBust() {
        setTableOperation('hideBust')
    };

    function handleClickSortAz() {
        setTableOperation('sortAtoZ')
    };

    function handleClickSortWin() {
        setTableOperation('sortByWins')
    };

    const pageColour = selectedCompetition ? selectedCompetition.compParams?.home_background : '#FFFFFF';

    return (
        <main style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            height: '100%',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            backgroundColor: `#${pageColour}`,
        }}>
            <div style={{
                display: 'flex',
                paddingTop: '3%',
                paddingBottom: '3%',
                margin: '0',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: `#${pageColour}`,
                width: '100%'
            }}>
                {selectedCompetition.name === 'TestComp' ? <img src="/dummies.jpg" alt="dummies" className="banner-img" /> : <img src="/pirates_banner.jpg" alt="Logo" className="banner-img" />}
            </div>
            <div className="standings-container">
                <button type="button" onClick={onCompetitions}>Competition Home</button>
                <h2>Competition Standings</h2>
                <ul className='filter-buttons-list'>
                    <li><button type='filter-button' onClick={handleClickShowAll}>Show All</button></li>
                    <li><button type='filter-button' onClick={handleClickHideBust}>Hide Bust</button></li>
                    <li><button type='filter-button' onClick={handleClickSortAz}>Sort by A-Z</button></li>
                    <li><button type='filter-button' onClick={handleClickSortWin}>Sort by Wins</button></li>
                </ul>
                <TableNew
                    round={round}
                    view={view}
                />
            </div>
        </main>
    );
}

export default Standings;
