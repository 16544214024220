import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { GetUserData } from './components/getUserData';
import { shapeTableArray } from './helpers/tableViews'

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['userToken']);
    const [authenticatedUser, setAuthenticatedUser] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [selectedCompetition, setSelectedCompetition] = useState({});
    const [competitionChanged, toggleCompetitionChanged] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [accountChanged, toggleAccountChanged] = useState(false);
    const [selectionsAgg, setSelectionsAgg] = useState([]);
    const [selectionsAggReset, toggleSelectionsAggReset] = useState(false);
    const [winningTeams, setWinningTeams] = useState([]);
    const [losingTeams, setLosingTeams] = useState([]);
    const [voidedTeams, setVoidedTeams] = useState([]);
    const [teamChanged, toggleTeamChanged] = useState(false);
    const [tableOperation, setTableOperation] = useState();
    const userToken = cookies.userToken && cookies.userToken !== 'undefined' ? cookies.userToken : null;

    // Fetch all data 
    const fetchData = async (token) => {
        let gameData = {}

        try {
            // Fetching user data
            const result = await GetUserData('userdata', token);
            if (result.status === 200) {
                const userPayload = result.data.payload;
                gameData = {
                    user: userPayload.user,
                    selectedCompetition: userPayload.selectedCompetition,
                    selectedAccount: userPayload.selectedAccount
                };
            } else if (result.status === 403) {
                console.log('Token invalid or expired');
            }
        } catch (err) {
            console.error('Error fetching user data:', err);
        }

        try {
            // Fetching selection data
            const result = await GetUserData("selections", token);
            if (result.status === 200) {
                const selectionsPayload = result.data.payload;

                // Flatten the selectionsPayload.accounts into a single array of selections with account name
                const flattenedSelections = selectionsPayload?.accounts?.reduce((acc, account) => {
                    const accountName = account.name;
                    const selections = account.selections.map(selection => ({
                        account_name: accountName,
                        ...selection
                    }));
                    return acc.concat(selections);
                }, []) || [];

                // Add selections to gameData
                gameData.selections = flattenedSelections;

            } else if (result.status === 403) {
                console.log('Token invalid or expired');
            }
        } catch (err) {
            console.error('Error fetching selections:', err);
        }

        let winners = [0];
        let losers = [0];
        let voids = [0];

        if (gameData.selectedAccount.selections) {
            gameData.selectedAccount.selections.forEach((selection) => selection.result === 'won' ? winners.push(selection.team_id) : null);

            gameData.selectedAccount.selections.forEach((selection) => selection.result === 'lost' ? losers.push(selection.team_id) : null);

            gameData.selectedAccount.selections.forEach((selection) => selection.result === 'void' ? voids.push(selection.team_id) : null);
        }

        // Update state after both fetches are complete
        setAuthenticatedUser(gameData.user || {});
        setSelectedCompetition(gameData.selectedCompetition || {});
        setSelectedAccount(gameData.selectedAccount || {});
        setSelectionsAgg(gameData.selections || []);
        setLosingTeams(losers);
        setWinningTeams(winners);
        setVoidedTeams(voids);
    };

    // Effect to update authentication status and fetch data based on userToken and changes
    useEffect(() => {
        if (userToken) {
            setIsAuthenticated(true);
            fetchData(userToken);  // Fetch user data when token is present
        } else {
            setIsAuthenticated(false);
        }
    }, [userToken, competitionChanged, accountChanged, teamChanged]);

    // Effect to update selectionsAgg if tableOperation is not null
    useEffect(() => {
        if (tableOperation) {

            const newArray = shapeTableArray(selectionsAgg, tableOperation);  // Fetch user data when token is present

            setSelectionsAgg(newArray || []);
            console.log(`table operation ${tableOperation} updated selectionsAgg`);
            setTableOperation();

            console.log('selectionsAgg', selectionsAgg);  // Add this inside your component

        }
    }, [tableOperation, selectionsAgg]);


    // Effect to reset competitionChanged after competition update
    useEffect(() => {
        const fetchAndResetCompetition = async () => {
            if (competitionChanged) {
                await fetchData(userToken);  // Wait for fetch to complete
                toggleCompetitionChanged(false);  // Reset competitionChanged after fetch completes
            }
        };
        fetchAndResetCompetition();  // Call the function
    }, [competitionChanged, userToken]);

    // Effect to reset accountChanged after account update
    useEffect(() => {
        const fetchAndResetAccount = async () => {
            if (accountChanged) {
                await fetchData(userToken);  // Wait for fetch to complete
                toggleAccountChanged(false);  // Reset accountChanged after fetch completes
            }
        };
        fetchAndResetAccount();  // Call the function
    }, [accountChanged, userToken]);

    // Effect to reset teamChanged after team update
    useEffect(() => {
        const fetchAndResetTeam = async () => {
            if (teamChanged) {
                await fetchData(userToken);  // Wait for fetch to complete
                toggleTeamChanged(false);  // Reset teamChanged after fetch completes
            }
        };
        fetchAndResetTeam();  // Call the function
    }, [teamChanged, userToken]);

    // Effect to reset selectionsAgg if toggled 
    useEffect(() => {
        const fetchAndResetSelectionsAgg = async () => {
            if (selectionsAggReset) {
                await fetchData(userToken);  // Wait for fetch to complete
                setTableOperation();
                console.log('selectionsAgg reset');
                toggleSelectionsAggReset(false);  // Reset selectionsAggReset after fetch completes
            }
        };
        fetchAndResetSelectionsAgg();  // Call the function
    }, [selectionsAggReset, userToken]);

    // Login function
    const handleLogin = async (newToken) => {
        setCookie('userToken', newToken, { path: '/' });
        setIsAuthenticated(true);
        await fetchData(newToken);  // Fetch and store user data after login
        console.log('Successfully logged in');
    };

    // Logout function
    const handleLogout = () => {
        removeCookie('userToken');  // Remove the userToken cookie
        setAuthenticatedUser({});  // Clear the user data
        setSelectionsAgg([]);  // Clear selections data
        setSelectedCompetition({});
        setSelectedAccount({});
        setIsAuthenticated(false);
        console.log('Successfully logged out');
    };

    return (
        <AppContext.Provider
            value={{
                authenticatedUser,
                setAuthenticatedUser,
                isAuthenticated,
                handleLogin,
                handleLogout,

                selectedCompetition,
                setSelectedCompetition,
                competitionChanged,
                toggleCompetitionChanged,

                selectedAccount,
                setSelectedAccount,
                accountChanged,
                toggleAccountChanged,

                selectionsAgg,
                setSelectionsAgg,
                selectionsAggReset,
                toggleSelectionsAggReset,
                setTableOperation,

                winningTeams,
                losingTeams,
                voidedTeams,

                teamChanged,
                toggleTeamChanged,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to use the AppContext
export const useAppContext = () => useContext(AppContext);
