import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

// backend URI
const backend = process.env.REACT_APP_SERVER_URI;

export function Auth() {
    const [loginOrRegister, setLoginOrRegister] = useState("login");
    const [passedEmail, setPassedEmail] = useState("");

    function handleShowLoginBtn() {
        setLoginOrRegister("login")
    }

    function handleShowRegisterBtn() {
        setLoginOrRegister("register")
    }

    function handleShowForgotPassBtn() {
        setLoginOrRegister("forgotPass")
    }

    function handleShowChangePasswordBtn() {
        setLoginOrRegister("changePass")
    }

    function handlePassEmail(receivedEmail) {
        setPassedEmail(receivedEmail)
    }

    return <main className="authPage">
        <div className='banner-LMS'>
            <img src="/logo.png" alt="Logo" className="banner-img-LMS" />
        </div>
        {loginOrRegister === "login" && (
            <Login
                loginOrRegister={loginOrRegister}
                handleShowRegisterBtn={handleShowRegisterBtn}
                passedEmail={passedEmail}
                handleShowForgotPassBtn={handleShowForgotPassBtn}
                handlePassEmail={handlePassEmail}
            />)}

        {loginOrRegister === "register" &&
            (<Register
                loginOrRegister={loginOrRegister}
                handleShowLoginBtn={handleShowLoginBtn}
                handlePassEmail={handlePassEmail}
                passedEmail={passedEmail}
            />)}

        {loginOrRegister === "forgotPass" &&
            (<ForgotPassword
                loginOrRegister={loginOrRegister}
                handleShowLoginBtn={handleShowLoginBtn}
                handleShowChangePasswordBtn={handleShowChangePasswordBtn}
                handlePassEmail={handlePassEmail}
                passedEmail={passedEmail}
            />)}

        {loginOrRegister === "changePass" &&
            (<ChangePassword
                loginOrRegister={loginOrRegister}
                passedEmail={passedEmail}
                handleShowLoginBtn={handleShowLoginBtn}
                handlePassEmail={handlePassEmail}
            />)}

    </main>
};

function Login({ loginOrRegister, handleShowForgotPassBtn, handleShowRegisterBtn, passedEmail }) {
    const { handleLogin, isAuthenticated } = useContext(AppContext); // Use context for login
    const [email, setEmail] = useState(passedEmail || "");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/competitions'); // Redirect if already authenticated
        }
    }, [isAuthenticated, navigate]);

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!email || !password) {
            return swal("Please enter your account email address and password");
        }

        try {
            const emailClean = email.toLowerCase().trim();
            // send UserToken = "none" to route to sign in with email and password
            const userToken = "none"

            const response = await axios.post(`${backend}/authenticate/signin`, {
                email: emailClean,
                password,
                userToken,
            });
            const responseAwait = await response.data;

            if (responseAwait && response.status === 200) {
            }

            if (response.data) {
                handleLogin(response.data.newToken); // Use handleLogin from context
                navigate('/competitions');
            }
        } catch (error) {
            swal("Invalid email or password");
        }
    };

    return (
        <div className='login-container'>
            <Form
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                label="Login"
                onSubmit={onSubmit}
                loginOrRegister={loginOrRegister}
                handleShowForgotPassBtn={handleShowForgotPassBtn}
                handleShowRegisterBtn={handleShowRegisterBtn}
            />
            <p></p>
            <button type='button' onClick={handleShowRegisterBtn}>Register</button>
            <p></p>
            <button type='button' onClick={handleShowForgotPassBtn}>Forgot Password?</button>

        </div>
    );
}

function Register({ loginOrRegister, handleShowLoginBtn, handlePassEmail, passedEmail }) {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState(passedEmail || "");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [phone, setPhone] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!username || !email || !password || !phone || password !== password2) {
            return swal("Please fill all fields and make sure passwords match");
        }

        try {
            const response = await axios.post(`${backend}/authenticate/signup`, { username, email, password, phone });
            if (response.data.status === "SUCCESS") {
                swal("Registration successful, please check your inbox to verify your email");
                handlePassEmail(email);
                navigate('/auth');
                handleShowLoginBtn();
            } else {
                swal(response.data.message);
            }
        } catch (error) {
            swal("An error occurred during registration");
        }
    };

    return (
        <div className='login-container'>
            <Form
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                password2={password2}
                setPassword2={setPassword2}
                phone={phone}
                setPhone={setPhone}
                label="Register"
                onSubmit={onSubmit}
                loginOrRegister={loginOrRegister}
                handleShowLoginBtn={handleShowLoginBtn}
            />
            <p></p>
            <button type='button' onClick={handleShowLoginBtn}>Return to Login</button>
        </div>
    );
}

function ForgotPassword({ loginOrRegister, handleShowLoginBtn, handleShowChangePasswordBtn, handlePassEmail, passedEmail }) {
    const [email, setEmail] = useState(passedEmail || "");

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!email) {
            return swal("Please enter your account email address");
        }

        try {
            const response = await axios.post(`${backend}/authenticate/forgotpass`, { email });
            if (response.data.status === "SUCCESS") {
                swal("Password reset link sent to your email");
                handlePassEmail(email);
                handleShowChangePasswordBtn();
            } else {
                swal(response.data.message);
            }
        } catch (error) {
            swal("Error sending reset password email");
        }
    };

    return (
        <div className='login-container'>
            <Form
                email={email}
                setEmail={setEmail}
                label="Forgot Password"
                onSubmit={onSubmit}
                loginOrRegister={loginOrRegister}
                handleShowLoginBtn={handleShowLoginBtn}
            />
            <p></p>
            <button type='button' onClick={handleShowLoginBtn}>Return to Login</button>
        </div>
    );
}

function ChangePassword({ loginOrRegister, handleShowLoginBtn, passedEmail }) {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!password || password !== password2) {
            return swal("Passwords don't match or are empty");
        }

        try {
            const response = await axios.post(`${backend}/authenticate/changepass`, { email: passedEmail, password });
            if (response.data.status === "SUCCESS") {
                swal("Password changed. Please log in with your new password");
                handleShowLoginBtn();
                navigate('/auth');
            } else {
                swal(response.data.message);
            }
        } catch (error) {
            swal("Error changing password");
        }
    };

    return (
        <div className='login-container'>
            <Form
                email={passedEmail}
                password={password}
                setPassword={setPassword}
                password2={password2}
                setPassword2={setPassword2}
                label="Change Password"
                onSubmit={onSubmit}
                loginOrRegister={loginOrRegister}
                handleShowLoginBtn={handleShowLoginBtn}
            />
            <p></p>
            <button type='button' onClick={handleShowLoginBtn}>Return to Login</button>
        </div>
    );
}

const Form = ({ username, setUsername, password, setPassword, password2, setPassword2, label, onSubmit, email, setEmail, loginOrRegister, setPhone, phone }) => {
    return (
        <div >
            <form onSubmit={onSubmit} className="form" >
                <div className="form-group">
                    <h2> {label} </h2>
                </div>

                {loginOrRegister === "register" &&
                    (<div className="form-group">
                        <label htmlFor="username"> Full name: </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)} />
                    </div>)}

                {(loginOrRegister === "register" || loginOrRegister === "login") &&
                    (<div className="form-group">
                        <label htmlFor="email"> Email: </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </div>)}

                {loginOrRegister === "forgotPass" &&
                    (<div className="form-group">
                        <label htmlFor="email"> Email: </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </div>)}


                {loginOrRegister === "register" &&
                    (<div className="form-group">
                        <label htmlFor="phone"> Contact Number: </label>
                        <input
                            type="text"
                            id="phone"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)} />
                    </div>)}

                {(loginOrRegister === "register" || loginOrRegister === "login") && (<div className="form-group">
                    <label htmlFor="password"> Password: </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)} />
                </div>)}

                {loginOrRegister === "changePass" && (<div className="form-group">
                    <label htmlFor="password"> Password: </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)} />
                </div>)}

                {((loginOrRegister === "changePass") || (loginOrRegister === "register")) &&
                    (<div className="form-group">
                        <label htmlFor="password"> Repeat Password: </label>
                        <input
                            type="password"
                            id="password2"
                            value={password2}
                            onChange={(event) => setPassword2(event.target.value)} />
                    </div>)}



                <div className="form-group"><button type="submit"> {loginOrRegister === "register" || loginOrRegister === "login" || loginOrRegister === "changePass" ? label : "Send password reset"}</button></div>

            </form>
        </div>
    )
};